import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { EventType } from "@azure/msal-browser";
import MsalApp from "../app/MsalApp";
import MsalInstance from "../auth/utils/MsalInstance";
import { loginRequest } from "../auth/authConfig";
import "../i18n";

const msalInstance = MsalInstance.getInstance();
msalInstance.initialize().then(async () => {
  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  msalInstance.handleRedirectPromise().then((authResult) => {
    // Check if user signed in
    const account = msalInstance.getActiveAccount();

    if (!account && !localStorage.getItem("loggedOut")) {
      // redirect anonymous user to login page
      msalInstance.loginRedirect(loginRequest);
    }
  });

  const container = document.getElementById("root");
  const root = ReactDOM.createRoot(container);

  root.render(
    <Router>
      <MsalApp msalInstance={msalInstance} />
    </Router>
  );
});
