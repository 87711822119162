import { TdsButton, TdsTypography } from "@tds/react";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import McdAutocomplete from "../../common/McdAutocomplete";
import Footer from "../Footer";
import useLayoutType from "../../hooks/useLayoutType";
import { Divider } from "@mui/material";
import styled from "@emotion/styled";
import { getZoom } from "../../utils/sizeUtils";
import { fetch } from "../../utils/fetch";
import { ORGANIZATION_STRUCTURE_ENDPOINT } from "../../constants";

const StyledFirstPage = styled("first")(({ theme }) => {
  let zoom = getZoom();
  return {
    ".zoom": {
      zoom: `${zoom}%`,
    },

    ".first-page-text": {
      zoom: `${zoom}%`,
    },

    ".question": {
      zoom: `${zoom}%`,
    },
    ".dropdown-text": {
      zoom: `${zoom}%`,
    },
    ".btn-previous": {
      zoom: `${zoom}%`,
    },
    ".btn-next": {
      zoom: `${zoom}%`,
    },
  };
});
export default function FirstPage({
  setStep,
  payload,
  setPayload,
  countries,
  setCountries,
  gbus,
  setGbus,
  bls,
  setBls,
  ccs,
  setCcs,
  setProjectName,
  msalInstance,
}) {
  const { i18n } = useTranslation();
  const layoutType = useLayoutType();
  let zoom = getZoom();
  const [selectedCountry, setSelectedCountry] = useState(
    payload.country ? payload.country : []
  );
  const [selectedGbu, setSelectedGbu] = useState(
    payload.gbuCode ? payload.gbuCode : []
  );
  const [selectedBl, setSelectedBl] = useState(
    payload.blCode ? payload.blCode : []
  );
  const [selectedCc, setSelectedCc] = useState(
    payload.ccName ? payload.ccName : []
  );
  const [lastSelected, setLastSelected] = useState(null);
  const [prevOrgType, setPrevOrgType] = useState(null);

  useEffect(() => {
    if (payload.country) {
      const defaultCountry = countries.find(
        (country) => country.orgValue === payload.country
      );
      if (defaultCountry) {
        setSelectedCountry(defaultCountry);
      }
    }
    if (payload.gbuCode) {
      const defaultGbu = gbus.find((gbu) => gbu.orgValue === payload.gbuCode);
      if (defaultGbu) {
        setSelectedGbu(defaultGbu);
      }
    }

    if (payload.blCode) {
      const defaultBl = bls.find((bl) => bl.orgValue === payload.blCode);
      if (defaultBl) {
        setSelectedBl(defaultBl);
      }
    }

    if (payload.ccName) {
      const defaultCc = ccs.find((cc) => cc.orgValue === payload.ccName);
      if (defaultCc) {
        setSelectedCc(defaultCc);
      }
    }
  }, []);

  useEffect(() => {
    if (
      !payload.country &&
      !payload.gbuCode &&
      !payload.blCode &&
      !payload.ccName
    ) {
      (async () => {
        try {
          var requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              orgType: lastSelected,
              prevOrgType: prevOrgType,
              selectedCountry: selectedCountry.orgValue
                ? selectedCountry.orgValue
                : null,
              selectedGbu: selectedGbu.orgValue ? selectedGbu.orgValue : null,
              selectedBl: selectedBl.orgValue ? selectedBl.orgValue : null,
              selectedCc: selectedCc.orgValue ? selectedCc.orgValue : null,
              selectedLanguage: i18n.language,
              countries: countries ? countries : null,
              gbus: gbus ? gbus : null,
              bls: bls ? bls : null,
              ccs: ccs ? ccs : null,
            }),
          };

          await fetch(ORGANIZATION_STRUCTURE_ENDPOINT, requestOptions)
            .then((response) => {
              if (response.data.countries.length > 0) {
                setCountries(response.data.countries);
              }
              if (response.data.gbus.length > 0) {
                setGbus(response.data.gbus);
              }
              if (response.data.bls.length > 0) {
                setBls(response.data.bls);
              }
              if (response.data.ccs.length > 0) {
                setCcs(response.data.ccs);
              }

              setPrevOrgType(response.data.prevOrgType);

              if (response.data && !payload.country) {
                setSelectedCountry({ orgValue: response.data.selectedCountry });
                setPayload((prev) => ({
                  ...prev,
                  country:
                    response.data.selectedCountry === "I dont know" ||
                    response.data.selectedCountry === "Je ne sais pas"
                      ? null
                      : response.data.selectedCountry,
                }));
              }
              if (response.data && !payload.gbuCode) {
                setSelectedGbu({ orgValue: response.data.selectedGbu });
                setPayload((prev) => ({
                  ...prev,
                  gbuCode:
                    response.data.selectedGbu === "I dont know" ||
                    response.data.selectedGbu === "Je ne sais pas"
                      ? null
                      : response.data.selectedGbu,
                }));
              }
              if (response.data && !payload.blCode) {
                setSelectedBl({ orgValue: response.data.selectedBl });
                setPayload((prev) => ({
                  ...prev,
                  blCode:
                    response.data.selectedBl === "I dont know" ||
                    response.data.selectedBl === "Je ne sais pas"
                      ? null
                      : response.data.selectedBl,
                }));
              }
              if (response.data && !payload.ccName) {
                setSelectedCc({ orgValue: response.data.selectedCc });
                setPayload((prev) => ({
                  ...prev,
                  ccName: response.data.selectedCc,
                }));
              }
            })
            .catch((error) => console.log("error", error));
        } catch (e) {
          // Handle generic exception
          console.info(`Req error: ${e.message}`);
          // window.location.reload();
        }
      })();
    }

    setPayload((prev) => ({
      ...prev,
      language:
        i18n.language === "en"
          ? "English (United States)"
          : "Français (France)",
    }));
  }, [i18n.language, msalInstance]);

  const handleSelectCountry = async (value) => {
    if (value) {
      setSelectedCountry(value);
      setLastSelected(value.orgType);
      try {
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orgType: "country",
            prevOrgType: prevOrgType,
            selectedCountry: value.orgValue,
            selectedGbu: selectedGbu.orgValue ? selectedGbu.orgValue : null,
            selectedBl: selectedBl.orgValue ? selectedBl.orgValue : null,
            selectedCc: selectedCc.orgValue ? selectedCc.orgValue : null,
            selectedLanguage: i18n.language,
            countries: countries ? countries : null,
            gbus: gbus ? gbus : null,
            bls: bls ? bls : null,
            ccs: ccs ? ccs : null,
          }),
        };
        if (value.orgValue !== selectedCountry.orgValue) {
          await fetch(ORGANIZATION_STRUCTURE_ENDPOINT, requestOptions).then(
            (response) => {
              if (response.data.gbus.length > 0) {
                setGbus(response.data.gbus);
              }
              if (response.data.bls.length > 0) {
                setBls(response.data.bls);
              }
              if (response.data.ccs.length > 0) {
                setCcs(response.data.ccs);
              }
              setPrevOrgType(response.data.prevOrgType);
              if (response.data) {
                setSelectedCountry({ orgValue: response.data.selectedCountry });
                setSelectedGbu({ orgValue: response.data.selectedGbu });
                setSelectedBl({ orgValue: response.data.selectedBl });
                setSelectedCc({ orgValue: response.data.selectedCc });
                setPayload((prev) => ({
                  ...prev,
                  country:
                    response.data.selectedCountry === "I dont know" ||
                    response.data.selectedCountry === "Je ne sais pas"
                      ? null
                      : response.data.selectedCountry,
                  gbuCode:
                    response.data.selectedGbu === "I dont know" ||
                    response.data.selectedGbu === "Je ne sais pas"
                      ? null
                      : response.data.selectedGbu,
                  blCode:
                    response.data.selectedBl === "I dont know" ||
                    response.data.selectedBl === "Je ne sais pas"
                      ? null
                      : response.data.selectedBl,
                  ccName: response.data.selectedCc,
                }));
              }
            }
          );
        }
      } catch (e) {
        console.info(`Req error: ${e.message}`);
        return window.location.reload();
        if (e.msg === "login_required") {
          window.location.reload();
          console.log("!!!Error", e);
          return e.msg;
        }
      }
    }
  };

  const handleSelectGbu = async (value) => {
    if (value) {
      setSelectedGbu(value);
      setLastSelected(value.orgType);
      try {
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orgType: "gbu",
            prevOrgType: prevOrgType,
            selectedCountry: selectedCountry.orgValue
              ? selectedCountry.orgValue
              : null,
            selectedGbu: value.orgValue,
            selectedBl: selectedBl.orgValue ? selectedBl.orgValue : null,
            selectedCc: selectedCc.orgValue ? selectedCc.orgValue : null,
            selectedLanguage: i18n.language,
            countries: countries ? countries : null,
            gbus: gbus ? gbus : null,
            bls: bls ? bls : null,
            ccs: ccs ? ccs : null,
          }),
        };
        if (value.orgValue !== selectedGbu.orgValue) {
          await fetch(ORGANIZATION_STRUCTURE_ENDPOINT, requestOptions).then(
            (response) => {
              if (response.data.countries.length > 0) {
                setCountries(response.data.countries);
              }
              if (response.data.bls.length > 0) {
                setBls(response.data.bls);
              }
              if (response.data.ccs.length > 0) {
                setCcs(response.data.ccs);
              }
              setPrevOrgType(response.data.prevOrgType);
              if (response.data) {
                setSelectedCountry({ orgValue: response.data.selectedCountry });
                setSelectedGbu({ orgValue: response.data.selectedGbu });
                setSelectedBl({ orgValue: response.data.selectedBl });
                setSelectedCc({ orgValue: response.data.selectedCc });
                setPayload((prev) => ({
                  ...prev,
                  country:
                    response.data.selectedCountry === "I dont know" ||
                    response.data.selectedCountry === "Je ne sais pas"
                      ? null
                      : response.data.selectedCountry,
                  gbuCode:
                    response.data.selectedGbu === "I dont know" ||
                    response.data.selectedGbu === "Je ne sais pas"
                      ? null
                      : response.data.selectedGbu,
                  blCode:
                    response.data.selectedBl === "I dont know" ||
                    response.data.selectedBl === "Je ne sais pas"
                      ? null
                      : response.data.selectedBl,
                  ccName: response.data.selectedCc,
                }));
              }
            }
          );
        }
      } catch (e) {
        console.info(`Req error: ${e.message}`);
        return window.location.reload();
        if (e.msg === "login_required") {
          window.location.reload();
          console.log("!!!Error", e);
          return e.msg;
        }
      }
    }
  };

  const handleSelectBl = async (value) => {
    if (value) {
      setSelectedBl(value);
      setLastSelected(value.orgType);
      try {
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orgType: "bl",
            prevOrgType: prevOrgType,
            selectedCountry: selectedCountry.orgValue
              ? selectedCountry.orgValue
              : null,
            selectedGbu: selectedGbu.orgValue ? selectedGbu.orgValue : null,
            selectedBl: value.orgValue,
            selectedCc: selectedCc.orgValue ? selectedCc.orgValue : null,
            selectedLanguage: i18n.language,
            countries: countries ? countries : null,
            gbus: gbus ? gbus : null,
            bls: bls ? bls : null,
            ccs: ccs ? ccs : null,
          }),
        };
        if (value.orgValue !== selectedBl.orgValue) {
          await fetch(ORGANIZATION_STRUCTURE_ENDPOINT, requestOptions).then(
            (response) => {
              if (response.data.countries.length > 0) {
                setCountries(response.data.countries);
              }
              if (response.data.gbus.length > 0) {
                setGbus(response.data.gbus);
              }
              if (response.data.ccs.length > 0) {
                setCcs(response.data.ccs);
              }
              setPrevOrgType(response.data.prevOrgType);
              if (response.data) {
                setSelectedCountry({ orgValue: response.data.selectedCountry });
                setSelectedGbu({ orgValue: response.data.selectedGbu });
                setSelectedBl({ orgValue: response.data.selectedBl });
                setSelectedCc({ orgValue: response.data.selectedCc });
                setPayload((prev) => ({
                  ...prev,
                  country:
                    response.data.selectedCountry === "I dont know" ||
                    response.data.selectedCountry === "Je ne sais pas"
                      ? null
                      : response.data.selectedCountry,
                  gbuCode:
                    response.data.selectedGbu === "I dont know" ||
                    response.data.selectedGbu === "Je ne sais pas"
                      ? null
                      : response.data.selectedGbu,
                  blCode:
                    response.data.selectedBl === "I dont know" ||
                    response.data.selectedBl === "Je ne sais pas"
                      ? null
                      : response.data.selectedBl,
                  ccName: response.data.selectedCc,
                }));
              }
            }
          );
        }
      } catch (e) {
        console.info(`Req error: ${e.message}`);
        return window.location.reload();
        if (e.msg === "login_required") {
          window.location.reload();
          console.log("!!!Error", e);
          return e.msg;
        }
      }
    }
  };

  const handleSelectCc = async (value) => {
    if (value) {
      setSelectedCc(value);
      setLastSelected(value.orgType);
      try {
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orgType: "cc",
            prevOrgType: prevOrgType,
            selectedCountry: selectedCountry.orgValue
              ? selectedCountry.orgValue
              : null,
            selectedGbu: selectedGbu.orgValue ? selectedGbu.orgValue : null,
            selectedBl: selectedBl.orgValue ? selectedBl.orgValue : null,
            selectedCc: value.orgValue,
            selectedLanguage: i18n.language,
            countries: countries ? countries : null,
            gbus: gbus ? gbus : null,
            bls: bls ? bls : null,
            ccs: ccs ? ccs : null,
          }),
        };
        if (value.orgValue !== selectedCc.orgValue) {
          await fetch(ORGANIZATION_STRUCTURE_ENDPOINT, requestOptions).then(
            (response) => {
              if (response.data.countries.length > 0) {
                setCountries(response.data.countries);
              }
              if (response.data.gbus.length > 0) {
                setGbus(response.data.gbus);
              }
              if (response.data.bls.length > 0) {
                setBls(response.data.bls);
              }
              setPrevOrgType(response.data.prevOrgType);
              if (response.data) {
                setSelectedCountry({ orgValue: response.data.selectedCountry });
                setSelectedGbu({ orgValue: response.data.selectedGbu });
                setSelectedBl({ orgValue: response.data.selectedBl });
                setSelectedCc({ orgValue: response.data.selectedCc });
                setPayload((prev) => ({
                  ...prev,
                  country:
                    response.data.selectedCountry === "I dont know" ||
                    response.data.selectedCountry === "Je ne sais pas"
                      ? null
                      : response.data.selectedCountry,
                  gbuCode:
                    response.data.selectedGbu === "I dont know" ||
                    response.data.selectedGbu === "Je ne sais pas"
                      ? null
                      : response.data.selectedGbu,
                  blCode:
                    response.data.selectedBl === "I dont know" ||
                    response.data.selectedBl === "Je ne sais pas"
                      ? null
                      : response.data.selectedBl,
                  ccName: response.data.selectedCc,
                }));
              }
            }
          );
        }
      } catch (e) {
        console.info(`Req error: ${e.message}`);
        return window.location.reload();
        if (e.msg === "login_required") {
          window.location.reload();
          console.log("!!!Error", e);
          return e.msg;
        }
      }
    }
  };

  const handleClick = () => {
    setStep((step) => {
      return step + 1;
    });
  };

  const handleClickReset = () => {
    setSelectedCountry([]);
    setSelectedGbu([]);
    setSelectedBl([]);
    setSelectedCc([]);
    setProjectName("");
    setPrevOrgType(null);
    try {
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      };

      fetch(ORGANIZATION_STRUCTURE_ENDPOINT, requestOptions)
        .then((response) => {
          setCountries(response.data.countries);
          setGbus(response.data.gbus);
          setBls(response.data.bls);
          setCcs(response.data.ccs);
        })
        .catch((error) => console.log("error", error));
    } catch (e) {
      console.info(`Req error: ${e.message}`);
      return window.location.reload();
      if (e.msg === "login_required") {
        window.location.reload();
        console.log("!!!Error", e);
        return e.msg;
      }
    }
    setPayload((prev) => ({
      ...prev,
      country: null,
      gbuCode: null,
      blCode: null,
      ccName: null,
      pName: null,
      moodSatisfaction: null,
      moodComment: null,
      confindenceToDeliverSatisfaction: null,
      confindenceToDeliverComment: null,
      key: null,
    }));
  };
  return (
    <StyledFirstPage>
      <div id="first">
        <div
          className={`${
            zoom > 90 && layoutType === "desktop"
              ? "page-title big"
              : "page-title small"
          }`}
        >
          <TdsTypography classes="zoom" fontSize={4}>
            <Trans i18nKey="firstTitle"></Trans>
          </TdsTypography>
          <TdsTypography fontSize={8} classes="required zoom text-bluegrey-600">
            <Trans i18nKey="required"></Trans>
          </TdsTypography>
        </div>

        <div
          className={`${
            zoom > 90 && layoutType === "desktop"
              ? "dropdowns big"
              : "dropdowns small"
          }`}
        >
          <div
            className={layoutType === "mobile" ? "dropdown" : "dropdown left"}
          >
            {layoutType === "desktop" && (
              <TdsTypography
                classes="first-page-text mb-m text-bluegrey-900"
                fontSize={6}
              >
                <Trans i18nKey="ccText"></Trans>
              </TdsTypography>
            )}
            <TdsTypography fontSize="6" classes="question text-bluegrey-600">
              <p>
                <Trans i18nKey="q4"></Trans>
              </p>
            </TdsTypography>
            <TdsTypography
              fontSize={7}
              classes="dropdown-text mb-xs text-bluegrey-600"
            >
              <Trans i18nKey="dropdown"></Trans>
            </TdsTypography>
            <McdAutocomplete
              id="dropdown-cc"
              placeholder={`${
                i18n.language === "en"
                  ? "Select your answer"
                  : "Sélectionnez votre réponse"
              }`}
              options={ccs}
              value={selectedCc || ""}
              callback={handleSelectCc}
              selectedCc={selectedCc}
            />
          </div>
          {layoutType === "mobile" ? (
            ""
          ) : (
            <Divider className="centerd" orientation="vertical" flexItem />
          )}

          <div className={layoutType === "mobile" ? "" : "right-block"}>
            <div className="dropdown right">
              <TdsTypography fontSize="6" classes="question text-bluegrey-600">
                <p className="required-question">
                  <Trans i18nKey="q1"></Trans>
                </p>
              </TdsTypography>
              <TdsTypography
                fontSize={7}
                classes="dropdown-text mb-xs text-bluegrey-600"
              >
                <Trans i18nKey="dropdown"></Trans>
              </TdsTypography>
              <McdAutocomplete
                id="dropdown-country"
                placeholder={`${
                  i18n.language === "en"
                    ? "Select your answer"
                    : "Sélectionnez votre réponse"
                }`}
                options={countries || ""}
                value={selectedCountry || ""}
                callback={handleSelectCountry}
                selectedCountry={selectedCountry}
              />
            </div>
            <div className="dropdown right">
              <TdsTypography fontSize="6" classes="question text-bluegrey-600">
                <p className="required-question">
                  <Trans i18nKey="q2"></Trans>
                </p>
              </TdsTypography>
              <TdsTypography
                fontSize={7}
                classes="dropdown-text mb-xs text-bluegrey-600"
              >
                <Trans i18nKey="dropdown"></Trans>
              </TdsTypography>
              <McdAutocomplete
                id="dropdown-gbu"
                placeholder={`${
                  i18n.language === "en"
                    ? "Select your answer"
                    : "Sélectionnez votre réponse"
                }`}
                options={gbus}
                value={selectedGbu || ""}
                callback={handleSelectGbu}
                selectedGbu={selectedGbu}
              />
            </div>
            <div className="dropdown right">
              <TdsTypography fontSize="6" classes="question text-bluegrey-600">
                <p>
                  <Trans i18nKey="q3"></Trans>
                </p>
              </TdsTypography>
              <TdsTypography
                fontSize={7}
                classes="dropdown-text mb-xs text-bluegrey-600"
              >
                <Trans i18nKey="dropdown"></Trans>
              </TdsTypography>
              <McdAutocomplete
                id="dropdown-bl"
                placeholder={`${
                  i18n.language === "en"
                    ? "Select your answer"
                    : "Sélectionnez votre réponse"
                }`}
                options={bls}
                value={selectedBl || ""}
                callback={handleSelectBl}
                selectedBl={selectedBl}
              />
            </div>
          </div>
        </div>
        <div
          className={`${
            zoom > 90 && layoutType === "desktop"
              ? "buttons big"
              : "buttons small"
          }`}
        >
          <TdsButton
            id="btnReset"
            variant="outline"
            color="primary"
            classes="btn-previous"
            onClick={handleClickReset}
          >
            <Trans i18nKey="reset"></Trans>
          </TdsButton>
          <TdsButton
            id="btnNext"
            variant="filled"
            color="primary"
            disabled={!(selectedCountry.orgValue && selectedGbu.orgValue)}
            classes="btn-next"
            onClick={handleClick}
          >
            <Trans i18nKey="next"></Trans>
          </TdsButton>
        </div>
        <Footer />
      </div>
    </StyledFirstPage>
  );
}
