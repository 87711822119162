import { loginRequest, securityLogin } from "../authConfig";
import MsalInstance from "./MsalInstance";

export async function callMsGraph(url, params) {
  const accessToken = await getAccessToken();
  const { headers, ...rest } = params;
  headers.Authorization = `Bearer ${accessToken}`;

  const options = {
    method: "GET",
    headers: headers,
    ...rest,
  };

  return fetch(url, options);
}

export const getAccessToken = async () => {
  const response = await MsalInstance.getInstance().acquireTokenSilent({
    ...loginRequest,
    account: MsalInstance.getInstance().getActiveAccount(),
  });
  return response.idToken;
};

export async function checkUserAccess() {
  let response;
  try {
    response = await securityLogin();
  } catch (reason) {
    response = reason;
  }
  return response.status;
}
