import { useState, useEffect } from 'react';

const useLayoutType = () => {
    const [layoutType, setLayoutType] = useState('desktop')

    useEffect(() => {
      const updateLayoutType = () => {
        const newLayoutType = window.innerWidth < 769 ? 'mobile' : 'desktop'

        if(newLayoutType !== layoutType) {
            setLayoutType(newLayoutType)
        }
      }
      
      window.addEventListener("resize", updateLayoutType);
      
      updateLayoutType();

      return () => window.removeEventListener("resize", updateLayoutType)
      
    }, [layoutType]);

    return layoutType;
  }
  
  export default useLayoutType