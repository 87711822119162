import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FirstPage from "./FirstPage";
import LastPage from "./LastPage";
import SecondPage from "./SecondPage";
import moment from "moment";
import { fetch } from "../../utils/fetch";
import Header from "../Header";
import { ORGANIZATION_STRUCTURE_ENDPOINT } from "../../constants";
import { useMsal } from "@azure/msal-react";

export default function Survey() {
  const msalInstance = useMsal();
  const [step, setStep] = useState(1);
  const DATE_TIME_FORMAT = "yyyy-MM-DDTHH:mm:ss";
  const { i18n } = useTranslation();

  const [countries, setCountries] = useState([]);
  const [gbus, setGbus] = useState([]);
  const [bls, setBls] = useState([]);
  const [ccs, setCcs] = useState([]);
  const [apiKey, setApiKey] = useState("");

  const [payload, setPayload] = useState({
    country: null,
    gbuCode: null,
    blCode: null,
    ccName: null,
    pName: null,
    moodSatisfaction: null,
    moodComment: null,
    confindenceToDeliverSatisfaction: null,
    confindenceToDeliverComment: null,
    language: "en",
    startDate: null,
    endDate: null,
    key: null,
  });
  const [projectName, setProjectName] = useState(
    payload.pName ? payload.pName : ""
  );
  useEffect(() => {
    (async () => {
      try {
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orgType: null,
            selectedCountry: null,
            selectedGbu: null,
            selectedBl: null,
            selectedCc: null,
            pName: null,
            selectedLanguage: i18n.language,
            countries: countries ? countries : null,
            gbus: gbus ? gbus : null,
            bls: bls ? bls : null,
            ccs: ccs ? ccs : null,
            key: null,
          }),
        };

        await fetch(ORGANIZATION_STRUCTURE_ENDPOINT, requestOptions)
          .then((response) => {
            setCountries(response.data.countries);
            setGbus(response.data.gbus);
            setBls(response.data.bls);
            setCcs(response.data.ccs);
            setApiKey(response.data.key);
          })
          .catch((error) => console.log("error", error));
      } catch (e) {
        console.info(`Req error: ${e.message}`);
        return window.location.reload();
      }
    })();

    setPayload((prev) => ({
      ...prev,
      startDate: moment(Date.now()).format(DATE_TIME_FORMAT),
    }));
  }, [msalInstance]);

  return (
    <>
      {step !== 3 && (
        <div className="main-section">
          <Header step={step} />
          {step === 1 && (
            <FirstPage
              countries={countries}
              setCountries={setCountries}
              gbus={gbus}
              setGbus={setGbus}
              bls={bls}
              setBls={setBls}
              ccs={ccs}
              setCcs={setCcs}
              payload={payload}
              setPayload={setPayload}
              setStep={setStep}
              projectName={projectName}
              setProjectName={setProjectName}
              msalInstance={msalInstance}
            />
          )}

          {step === 2 && (
            <SecondPage
              apiKey={apiKey}
              step={step}
              setStep={setStep}
              payload={payload}
              setPayload={setPayload}
              projectName={projectName}
              setProjectName={setProjectName}
            />
          )}
        </div>
      )}
      {step === 3 && <LastPage />}
    </>
  );
}
