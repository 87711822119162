import React from "react";
import { TdsTypography } from "@tds/react";

export default function McdTypography({ children, classes, component, fontFamily, fontSize, fontWeight, tag, title }) {
  const typographyProps = { children, classes, component, fontFamily, fontSize, fontWeight, tag, title };

  const getTypographyProps = () => {
    const availableProps = {};
    Object.keys(typographyProps).forEach(key => {
      if (typographyProps[key]) {
        availableProps[key] = typographyProps[key];
      }
    });
    return availableProps;
  };

  return (
    <TdsTypography {...getTypographyProps()}>
      <p className={classes}>{children}</p>
    </TdsTypography>
  );
}
