import React from "react";
import Survey from "./Survey/Survey";
import Layout from "./Layout/Layout";
import "../styles/base.css";
import "../components/Layout/layout.css";
import { changeLanguage } from "i18next";

export default function App() {
  const language = window.navigator.language;
  if (language.includes("fr")) {
    changeLanguage("fr");
  }

  return (
    <Layout>
      <Survey />
    </Layout>
  );
}
