import { TdsTypography } from "@tds/react";
import React from "react";
import { Trans } from "react-i18next";
import logo from "../../images/ThalesLogo.png";
import "./styled.css"

export default function LastPage() {
    return (
        <div className="final-wrapper">
            <div className="final-header">
                <img className="final-logo" src={logo} />
            </div>
            <div className="final-content">
                <TdsTypography fontSize="4" classes="question mb-xxl"><p><Trans i18nKey="thankyou"></Trans></p></TdsTypography>
                <TdsTypography fontSize="7" classes="question-section text text-bluegrey-600">
                    <Trans i18nKey="q9"></Trans>
                </TdsTypography>
            </div>
        </div>
    );
}