const months = {
	en: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sept","Oct","Nov","Dec"],
    fr: ["Janv","Fév","Mars","Avril","Mai","Juin","Juil","Août","Sept","Oct","Nov","Déc"]
}

export const getMonthRange = (lang) => {
  let currentMonth;
  let nextMonth;
  
  switch (new Date().getMonth()) {
    case 0:
    case 1:
      currentMonth = months[lang][0];
      nextMonth =  months[lang][1]
    break;
    
    case 2:
    case 3:
      currentMonth = months[lang][2];
      nextMonth = months[lang][3];
    break;
    
    case 4:
    case 5:
      currentMonth = months[lang][4];
      nextMonth = months[lang][5];
    break
    
    case 6:
    case 7:
      currentMonth = months[lang][6];
      nextMonth = months[lang][7];
    break
    
    case 8:
    case 9:
      currentMonth = months[lang][8];
      nextMonth = months[lang][9];
    break;
    
    case 10:
    case 11:
      currentMonth = months[lang][10];
      nextMonth = months[lang][11];
    break
  }

  return `${currentMonth} / ${nextMonth}`
}
