import { MsalProvider } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import App from "../components/App";
import { CustomNavigationClient } from "../auth/utils/NavigationClient";

function MsalApp({ msalInstance }) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  msalInstance.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  );
}

export default MsalApp;
