import React from "react";
import { createRoot } from "react-dom/client";
import pulseTracker from "../images/pulseTracker.svg";
import CookiesLayout from "../components/Layout/CookiesLayout/CookiesLayout";
import "../styles/base.css";
import {
  StyledCookiesContainer,
  StyledCookiesWarning,
  StyledCookiesStep,
  StyledCookiesLogo,
} from "../components/Layout/CookiesLayout/styled";
import McdTypography from "../common/McdTypography";
import McdIcon from "../common/McdIcon";

const container = document.getElementById("root");
const root = createRoot(container);

const browserType = (function (agent) {
  let firstText = "Looks like your Cookies are disabled.";
  let secondText = "In order to use pulseTracker, please follow the steps:";
  if (!(agent.indexOf("edg/") > -1)) {
    firstText = "Seems that you are not using Microsoft Edge.";
    secondText = "For best experience using pulseTracker, we recommend Microsoft Edge and turning on cookies:";
  }
  return { firstText, secondText };
})(window.navigator.userAgent.toLowerCase());


const app = (
  <CookiesLayout>
    <StyledCookiesContainer id="cookies-container">
      <div className="flex justify-center items-center mt-7xl">
        <StyledCookiesLogo src={pulseTracker} alt="pulseTracker" />
      </div>
      <div className="flex flex-col justify-start items-center container pl-8xl mt-3xl">
        <div className="flex justify-start items-center container my-xl">
          <StyledCookiesWarning>
            <McdIcon marginBottom="4px" icon="warning_amber" size="xxlarge" />
          </StyledCookiesWarning>
          <div className="flex flex-col">
            <McdTypography fontSize={5} classes="text-bluegrey-600">
              {browserType.firstText}
            </McdTypography>
            <McdTypography fontSize={5} classes="text-bluegrey-600">
              {browserType.secondText}
            </McdTypography>
          </div>
        </div>
        <div className="flex justify-start items-center container my-xl">
          <StyledCookiesStep>
            <McdTypography
              fontSize={7}
              fontWeight="bold"
              classes="text-bluegrey-600"
            >
              1
            </McdTypography>{" "}
          </StyledCookiesStep>
          <McdTypography fontSize={6} classes="text-bluegrey-600">
            Click the Menu button
          </McdTypography>
          <McdIcon
            classes="cookie-menu text-bluegrey-600"
            size={"xlarge"}
            icon="more_horiz"
          />
          <McdTypography fontSize={6} classes="text-bluegrey-600">
            found at the top-right corner of the browser, and then click
            Settings.
          </McdTypography>
        </div>
        <div className="flex justify-start items-center container my-xl">
          <StyledCookiesStep>
            <McdTypography
              fontSize={7}
              fontWeight="bold"
              classes="text-bluegrey-600"
            >
              2
            </McdTypography>{" "}
          </StyledCookiesStep>
          <McdTypography fontSize={6} classes="text-bluegrey-600">
            Click Cookies and site permissions and then Manage and delete
            cookies and site data.
          </McdTypography>
        </div>
        <div className="flex justify-start items-center container my-xl">
          <StyledCookiesStep>
            <McdTypography
              fontSize={7}
              fontWeight="bold"
              classes="text-bluegrey-600"
            >
              3
            </McdTypography>{" "}
          </StyledCookiesStep>
          <McdTypography fontSize={6} classes="text-bluegrey-600">
            Choose the option Allow sites to save and read cookie data.
          </McdTypography>
        </div>
        <div className="flex justify-start items-center container my-xl">
          <StyledCookiesStep>
            <McdTypography
              fontSize={7}
              fontWeight="bold"
              classes="text-bluegrey-600"
            >
              4
            </McdTypography>{" "}
          </StyledCookiesStep>
          <McdTypography fontSize={6} classes="text-bluegrey-600">
            Refresh the page (CTRL + F5).
          </McdTypography>
        </div>
      </div>
    </StyledCookiesContainer>
  </CookiesLayout>
);

root.render(app);
