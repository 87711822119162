import { CLIENT_ID, getIsAzure, TENANT_ID } from "./auth/authConfig";

async function bootApp() {
  if (!navigator.cookieEnabled) {
    return require("./roots/disabledCookies.js");
  }
  const loginType = await getIsAzure();
  if (loginType.data.azure) {
    const { clientId, tenantId } = loginType.data;
    if (clientId && tenantId) {
      localStorage.setItem(CLIENT_ID, clientId);
      localStorage.setItem(TENANT_ID, tenantId);
      return require("./roots/msalRoot.js");
    }
  } 
}

bootApp();
