import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, TextField, styled } from "@mui/material";
import useLayoutType from "../hooks/useLayoutType";
import { TdsIcon } from "@tds/react";
import { getZoom } from "../utils/sizeUtils";

const StyledAutocomplete = styled(Autocomplete)(
  ({ successSelection }) => {
    return{
      ".MuiOutlinedInput-root .MuiAutocomplete-endAdornment": {
        visibility: successSelection ? "hidden" : "visible",
      },
      ".Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "2px solid #041295 !important",
      },
      ".MuiAutocomplete-input": {
        fontSize: "14px !important",
        height: "18px !important"
      },
      ".MuiAutocomplete-root": {
        height: "30px"
      },
      ".success": {
        position: "relative",
        right: "5px",
      },
    }

  }
);

export default function McdAutocomplete({
  callback,
  options,
  value,
  placeholder,
  id,
  selectedCountry,
  selectedGbu,
  selectedBl,
  selectedCc,
}) {
  const layoutType = useLayoutType();
  const [successSelection, setSuccessSelection] = useState(false);

  useEffect(() => {
    if (selectedCountry) {
      setSuccessSelection(selectedCountry.orgValue ? true : false);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedGbu) {
      setSuccessSelection(selectedGbu.orgValue ? true : false);
    }
  }, [selectedGbu]);

  useEffect(() => {
    if (selectedBl) {
      setSuccessSelection(selectedBl.orgValue ? true : false);
    }
  }, [selectedBl]);

  useEffect(() => {
    if (selectedCc) {
      setSuccessSelection(selectedCc.orgValue ? true : false);
    }
  }, [selectedCc]);

  const handleChange = (event, value, reason, details) => {
    if (value) {
      callback(value);
    } else {
      callback(null);
    }
  };

  return (
    <StyledAutocomplete
      successSelection={successSelection}
      layoutType={layoutType}
      key={id}
      size="small"
      disableClearable
      id={id}
      options={options}
      onChange={handleChange}
      value={value}
      clearIndicator={true}
      placeholder={placeholder}
      getOptionSelected={(option, value) => option.orgValue === value.orgValue}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            disableUnderline: true,
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {successSelection ? (
                  <TdsIcon
                    icon="check_circle"
                    classes="text-green-600 success"
                    size={20}
                  />
                ) : (
                  ""
                )}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        let zoom = getZoom();
        return (
          <li {...props}>
            <Box
              sx={{
                fontSize: layoutType === "mobile" ? "12px" : "16px",
                // zoom: `${zoom}%`,
              }}
            >
              {option.orgValue || ""}
            </Box>
          </li>
        );
      }}
      getOptionLabel={(option) => option.orgValue || ""}
    />
  );
}
