import React, { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation, Trans } from "react-i18next";
import { FormControl, TextField } from "@mui/material";
import Rating from "@mui/material/Rating";
import { TdsButton, TdsTextInput, TdsTypography } from "@tds/react";
import Footer from "../Footer";
import useLayoutType from "../../hooks/useLayoutType";
import styled from "@emotion/styled";
import { getZoom } from "../../utils/sizeUtils";
import { fetch } from "../../utils/fetch";
import { CREATE_SURVEY_ENDPOINT } from "../../constants";

const StyledSecondPage = styled("second")(({ theme }) => {
  let zoom = getZoom();
  return {
    ".second-page-title": {
      zoom: `${zoom}%`,
    },
    ".encrypted": {
      zoom: `${zoom}%`,
    },
    ".question": {
      zoom: `${zoom}%`,
    },
    ".prj": {
      zoom: `${zoom}%`,
    },
    ".mood": {
      zoom: `${zoom}%`,
    },
    ".text": {
      zoom: `${zoom}%`,
    },
    ".first-text": {
      zoom: `${zoom}%`,
    },
    ".btn-submit": {
      zoom: `${zoom}%`,
    },
    ".btn-previous": {
      zoom: `${zoom}%`,
    },
    ".required": {
      zoom: `${zoom}%`,
    },
    ".text-box": {
      zoom: `${zoom}%`,
    },
    ".characters": {
      zoom: `${zoom}%`,
    },
  };
});

function SecondPage({
  payload,
  setPayload,
  setStep,
  apiKey,
  projectName,
  setProjectName,
}) {
  let zoom = getZoom();
  // alert(zoom)
  var CryptoJS = require("crypto-js");
  const { i18n } = useTranslation();
  const DATE_TIME_FORMAT = "yyyy-MM-DDTHH:mm:ss";
  const [commentMood, setCommentMood] = useState(payload.moodComment);
  const [countMood, setCountMood] = useState(
    payload.moodComment ? payload.moodComment.length : 0
  );
  const [countProjectName, setCountProjectName] = useState(
    payload.pName ? payload.pName.length : 0
  );
  const [moodRating, setMoodRating] = useState(0 || payload.moodSatisfaction);

  const [commentConfidence, setCommentConfidence] = useState(
    payload.confindenceToDeliverComment
  );
  const [countConfidence, setCountConfidence] = useState(
    payload.confindenceToDeliverComment
      ? payload.confindenceToDeliverComment.length
      : 0
  );
  const [confidenceRating, setConfidenceRating] = useState(
    0 || payload.confindenceToDeliverSatisfaction
  );

  const [randomKey, setRandomKey] = useState("");
  const [encodedKey, setEncodedKey] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const layoutType = useLayoutType();
  // Payload string that have to be encoded
  const payloadString = JSON.stringify({
    ...payload,
    country: payload.country,
    gbuCode: payload.gbuCode,
    blCode: payload.blCode,
    ccName: payload.ccName,
    pName: payload.pName,
    moodComment: payload.moodComment,
    confindenceToDeliverComment: payload.confindenceToDeliverComment,
    language: payload.language,
    startDate: payload.startDate,
    endDate: moment(Date.now()).format(DATE_TIME_FORMAT),
  });

  useEffect(() => {
    let result = "";
    const publicKey =
      "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCJ71fznR1SccBZzeO7Eq4FpV5tKgJkQetWyhkXZ1QzOlvAkpmYa0q0GXFfj41tyQd2aLl9kWBJw6PmehdEFj0lY9U1DRx0mNDEfewQgk/yaEPVxe5Or25+7bfgv/0Jr045udYSuOfkCr/14ImEk45banplGGIS1xq4dQ2mNWVwCwIDAQAB";

    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 32) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    setRandomKey(result);

    (async () => {
      try {
        const publicKeyData = Uint8Array.from(atob(publicKey), (c) =>
          c.charCodeAt(0)
        );
        const dataBuffer = new TextEncoder().encode(result);

        const importedKey = await crypto.subtle.importKey(
          "spki",
          publicKeyData,
          { name: "RSA-OAEP", hash: "SHA-256" },
          false,
          ["encrypt"]
        );

        const encryptedArrayBuffer = await crypto.subtle.encrypt(
          { name: "RSA-OAEP" },
          importedKey,
          dataBuffer
        );

        const encryptedString = btoa(
          String.fromCharCode(...new Uint8Array(encryptedArrayBuffer))
        );
        setEncodedKey(encryptedString);
      } catch (error) {
        console.error("Encryption Error:", error);
      }
    })();
  }, []);

  useEffect(() => {
    if (moodRating) {
      setPayload((prev) => ({
        ...prev,
        moodSatisfaction: moodRating,
      }));
    }
  }, [moodRating]);

  useEffect(() => {
    if (confidenceRating) {
      setPayload((prev) => ({
        ...prev,
        confindenceToDeliverSatisfaction: confidenceRating,
      }));
    }
  }, [confidenceRating]);

  useEffect(() => {
    setPayload((prev) => ({
      ...prev,
      moodComment: commentMood,
    }));
  }, [commentMood]);

  useEffect(() => {
    setPayload((prev) => ({
      ...prev,
      confindenceToDeliverComment: commentConfidence,
    }));
  }, [commentConfidence]);

  useEffect(() => {
    if (moodRating && confidenceRating) {
      setCanSubmit(true);
    }
  }, [moodRating, confidenceRating]);

  function payloadEncription(payload) {
    if (canSubmit) {
      return encrypt(payload, randomKey);
    }
  }

  function encrypt(obj, key) {
    var keyEnc = CryptoJS.enc.Base64.parse(key);
    var objEnc = CryptoJS.enc.Utf8.parse(obj);
    var encrypted = CryptoJS.AES.encrypt(objEnc, keyEnc, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  }

  const handleClickPrevious = () => {
    setStep((step) => {
      return step - 1;
    });
  };

  const handleClickSubmit = async () => {
    if (!canSubmit) {
      return;
    }

    setStep((step) => {
      return step + 1;
    });

    setPayload((prev) => ({
      ...prev,
      endDate: moment(Date.now()).format(DATE_TIME_FORMAT),
    }));

    try {
      var requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          survey: payloadEncription(payloadString),
          key: encodedKey,
        }),
      };

      fetch(CREATE_SURVEY_ENDPOINT, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
    } catch (e) {
      console.info(`Req error: ${e.message}`);
      return window.location.reload();
      console.log("Error", e);
    }
  };

  const handleChangeMood = (value) => {
    setCommentMood(value.target.value);
    setCountMood(value.target.value.length);
  };

  const handleChangeConfidence = (value) => {
    setCommentConfidence(value.target.value);
    setCountConfidence(value.target.value.length);
  };
  const handleChangeProjectName = (value) => {
    setCountProjectName(value.target.value.length);
    setProjectName(value.target.value);
    setPayload((prev) => ({
      ...prev,
      pName: value.target.value,
    }));
  };
  return (
    <StyledSecondPage>
      <div id="second">
        <div>
          <div
            className={`${
              zoom > 90 && layoutType === "desktop"
                ? "questions"
                : "questions small"
            }`}
          >
            <div className="second-page-title">
              <TdsTypography classes="mt-m" fontSize={4}>
                <Trans i18nKey="pName"></Trans>
              </TdsTypography>
            </div>
            <div className="right">
              <TdsTypography fontSize="6" classes="question text-bluegrey-600">
                <p>
                  <Trans i18nKey="q5"></Trans>
                </p>
              </TdsTypography>
              <TdsTypography
                classes="text-bluegrey-600 encrypted text-italic mb-xs"
                fontSize={7}
              >
                <Trans i18nKey="projectNote"></Trans>
              </TdsTypography>
              <div className="prj">
                <TdsTextInput
                  inputProps={{
                    maxLength: 160,
                  }}
                  autofocus
                  autocomplete="off"
                  sx={{
                    placeholder: {
                      color: "red",
                    },
                  }}
                  inputId={`project-name`}
                  classes={`mt-xs ${
                    layoutType === "mobile" ? "project-mobile" : "project"
                  }`}
                  placeholder={`${
                    i18n.language === "en"
                      ? "Enter your answer"
                      : "Entrez votre réponse"
                  }`}
                  size="medium"
                  value={projectName}
                  onValueChanged={handleChangeProjectName}
                />
                <TdsTypography classes="text-bluegrey-300 characters-project">
                  {160 - countProjectName}{" "}
                  {i18n.language === "en"
                    ? "characters left"
                    : "caractères reste"}
                </TdsTypography>
              </div>
            </div>
            <div className="mood">
              <TdsTypography classes="mr-xs" fontSize={4}>
                <Trans i18nKey="q6Title"></Trans>
              </TdsTypography>
              <TdsTypography classes="required-question text-bluegrey-600">
                <Trans i18nKey="q5Body"></Trans>
              </TdsTypography>
            </div>

            <div className="review">
              <TdsTypography
                fontSize={8}
                classes="text-review mr-m text-bluegrey-600"
              >
                <Trans i18nKey="notSatisfied"></Trans>
              </TdsTypography>
              <div className="rate">
                <Rating
                  IconContainerComponent={(props) => {
                    const { value, ...other } = props;
                    return <label id={`mood-${value}`} {...other}></label>;
                  }}
                  id="mood-stars"
                  size="large"
                  name="simple-controlled"
                  value={moodRating}
                  onChange={(event, newValue) => {
                    setMoodRating(newValue);
                  }}
                  sx={{
                    "& .MuiRating-iconFilled": {
                      color: "#242a75",
                    },
                    "& .MuiRating-iconHover": {
                      color: "#242a75",
                    },
                  }}
                />
              </div>
              <TdsTypography
                fontSize={8}
                classes="text-review ml-m text-bluegrey-600"
              >
                <Trans i18nKey="satisfied"></Trans>
              </TdsTypography>
            </div>

            <TdsTypography classes="first-text text-bluegrey-600">
              <p>
                <Trans i18nKey="q6Body"></Trans>
              </p>
            </TdsTypography>
            <TdsTypography
              fontSize={7}
              classes="text text-italic text-bluegrey-600 mb-xs"
            >
              <Trans i18nKey="confidential"></Trans>
            </TdsTypography>

            <div className="text-box">
              <div className="text-area-box">
                <FormControl>
                  <TextField
                    id="mood-input-text"
                    multiline
                    placeholder={`${
                      i18n.language === "en"
                        ? "Enter your answer"
                        : "Entrez votre réponse"
                    }`}
                    value={commentMood}
                    onChange={handleChangeMood}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      maxLength: 500,
                    }}
                  />
                </FormControl>
              </div>
              <TdsTypography classes="text-bluegrey-300 characters">
                {500 - countMood}{" "}
                {i18n.language === "en"
                  ? "characters left"
                  : "caractères reste"}
              </TdsTypography>
            </div>

            <div className="mood">
              <TdsTypography classes="mr-xs" fontSize={4}>
                <Trans i18nKey="q8Title"></Trans>
              </TdsTypography>
              <TdsTypography classes="mt-xxs text-bluegrey-600 required-question">
                <Trans i18nKey="q7Body"></Trans>
              </TdsTypography>
            </div>
            <div className="review">
              <TdsTypography
                fontSize={8}
                classes="text-review mr-m text-bluegrey-600"
              >
                <Trans i18nKey="notSatisfied"></Trans>
              </TdsTypography>
              <div className="rate">
                <Rating
                  id="confidence-stars"
                  size="large"
                  name="simple-controlled"
                  value={confidenceRating}
                  IconContainerComponent={(props) => {
                    const { value, ...other } = props;
                    return (
                      <label id={`confidence-${value}`} {...other}></label>
                    );
                  }}
                  onChange={(event, newValue) => {
                    setConfidenceRating(newValue);
                  }}
                  sx={{
                    "& .MuiRating-iconFilled": {
                      color: "#242a75",
                    },
                    "& .MuiRating-iconHover": {
                      color: "#242a75",
                    },
                  }}
                />
              </div>
              <TdsTypography
                fontSize={8}
                classes="text-review ml-m text-bluegrey-600"
              >
                <Trans i18nKey="satisfied"></Trans>
              </TdsTypography>
            </div>

            <TdsTypography classes="first-text text-bluegrey-600">
              <Trans i18nKey="q8Body"></Trans>
            </TdsTypography>
            <TdsTypography
              fontSize={7}
              classes="text text-italic text-bluegrey-600 mb-xs"
            >
              <Trans i18nKey="confidential"></Trans>
            </TdsTypography>
            <div className="text-box">
              <div className="text-area-box">
                <FormControl>
                  <TextField
                    id="mood-input-text"
                    multiline
                    placeholder={`${
                      i18n.language === "en"
                        ? "Enter your answer"
                        : "Entrez votre réponse"
                    }`}
                    value={commentConfidence}
                    onChange={handleChangeConfidence}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      maxLength: 500,
                    }}
                  />
                </FormControl>
              </div>
              <TdsTypography classes="text-bluegrey-300 characters">
                {500 - countConfidence}{" "}
                {i18n.language === "en"
                  ? "characters left"
                  : "caractères reste"}
              </TdsTypography>
            </div>
            <TdsTypography fontSize={8} classes="required text-bluegrey-600">
              <Trans i18nKey="required"></Trans>
            </TdsTypography>
          </div>
        </div>
        <div className="buttons">
          <TdsButton
            id="btnPrevious"
            variant="outline"
            color="primary"
            classes="btn-previous"
            onClick={handleClickPrevious}
          >
            <Trans i18nKey="previous"></Trans>
          </TdsButton>
          <TdsButton
            id="btnSubmit"
            variant="filled"
            color="primary"
            classes="btn-submit"
            onClick={handleClickSubmit}
            disabled={!canSubmit}
          >
            <Trans i18nKey="submit"></Trans>
          </TdsButton>
        </div>
      </div>
      <Footer />
    </StyledSecondPage>
  );
}

export default SecondPage;
