import { API_ENDPOINT } from "../constants";
import { callMsGraph } from "../auth/utils/MsGraphApiCall";
import MsalInstance from "../auth/utils/MsalInstance";

const redirectToLogin = () => {
    const logout = async () => {
      const url = computeURL("security", "logout");
      const method = "GET";
      return fetch(url, fetchOptions({ method }));
    };
    logout().then(async () => {
        await MsalInstance.getInstance().logoutRedirect();
    });
  
};

/**
 * Check the status and reject the promise if it's not in the 200 range
 */
const checkStatus = res => {
  if (res.status >= 200 && res.status < 300) {
    return res;
  } else {
    if (
      res.url &&
      !res.url.endsWith("/security/login") &&
      (res.status === 401 || res.status === 403 || res.status === 504)
    ) {
      return redirectToLogin();
    }
    if (res.status === 401) { //TODO: CHECK WITH BOGDAN IF OK
      return redirectToLogin();
    }
    throw res;
  }
};

/**
 * Deserialize the request body
 */
const deserialize = res => {
  const header = res.headers.get("Content-Type") || "";
  if (header.indexOf("application/json") > -1) {
    return res.json();
  }
  if (header.indexOf("application/ld+json") > -1) {
    return res.json();
  }
  if (header.indexOf("application/octet-stream") > -1) {
    return res.arrayBuffer();
  }
  return res.text();
};

/**
 * Get default fetch options
 * @param {*object} options
 */
const fetchOptions = (options = {}) => {
  const headers = { "Content-Type": "application/json", Accept: "application/json" };

  const requestOptions = {
    method: "get",
    credentials: "include",
    headers: headers,
    ...options,
  };
  return requestOptions;
};

const fetchWrapper = async (url = "", params = {}, responseCallback = () => {}) => {
  if (params.hasOwnProperty("isPublic") ) {
    return fetch(url, params)
      .then(response => {
        responseCallback(response);
        return checkStatus(response);
      })
      .then(response => deserialize(response));
  }
  return callMsGraph(url, params)
    .then(response => {
      responseCallback(response);
      return checkStatus(response);
    })
    .then(response => deserialize(response));
};


/**
 * Build query string
 * @param {*object} data
 */
const queryString = data => {
  return (
    "?" +
    Object.keys(data)
      .map(val => {
        return `${val}=${data[val]}`;
      })
      .join("&")
  );
};

const computeURL = (root, action, id, params, urlParams) => {
  let tmp = `${API_ENDPOINT}/${root}`;
  if (action) {
    tmp += `/${action}`;
  }
  if (id || id === false) {
    tmp += `/${id}`;
  }
  if (params || params === 0 || params === false) {
    tmp += `/${params}`;
  }
  if (urlParams) {
    tmp += `${urlParams}`;
  }
  return tmp;
};

export { fetchWrapper as fetch, fetchOptions, queryString, computeURL };
